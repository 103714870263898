import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  FilteredList,
  GroupedList,
  MultipleComponentIterator,
  SlideReveal,
  HoverContainer,
  HorizontalImageCard,
  InternalLink,
  FishermanIcon,
  BasicCarousel,
  multipleComponentIterator,
  ReviewV2,
  TeamMemberV2,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class TeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
      allFishermanBusinessLocation,
      allFishermanBusinessTeamGroup,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
          ogUrl={"https://www.musesalonandspa.com/team/"}
        />
        <PageLayout hero={false} className={"team-page"}>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"team-content-container"}
          >
            <Grid.Column width={16}>
              <Header as={"h1"} className={""}>
                <span
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "team_header",
                    defaultValue: "Meet the",
                  })}
                />
                <span
                  className={"highlighted"}
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "team_header_highlighted",
                    defaultValue: "Team",
                  })}
                />
              </Header>
              <MarkdownContent
                className={"team-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "team_description",
                })}
              />
              <FilteredList
                items={allFishermanBusinessTeam.nodes}
                itemFilterKey={"locations.name"}
                childItemsKey={"items"}
                defaultFilterValue={"All Locations"}
                displayAllFilterValue={"All Locations"}
                filterValues={allFishermanBusinessLocation.nodes}
                filterValueKey={"name"}
                filterValuesComponent={<Button basic={true} />}
                className={"team-filtered-list"}
              >
                <GroupedList
                  groupBy={"teams.name"}
                  childItemsKey={"iterator"}
                  defaultGroupName={"Our Team"}
                  groupOrderOptions={{
                    defaultGroupPosition: "start",
                    orderKey: "name",
                    order: allFishermanBusinessTeamGroup.nodes,
                  }}
                  itemComponent={
                    <MultipleComponentIterator
                      components={[
                        {
                          component: (
                            <SlideReveal
                              className={"ui card team-member"}
                              fade={true}
                              direction={"up"}
                              triggerOnce={true}
                            />
                          ),
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: (
                                <HoverContainer
                                  className={""}
                                  forceDisplay={true}
                                />
                              ),
                              children: [
                                {
                                  component: (
                                    <HorizontalImageCard
                                      link={true}
                                      as={InternalLink}
                                      imageAsBackground={true}
                                      imagePosition={"left"}
                                      event={{
                                        category: "Team",
                                        action: "View Team Member",
                                      }}
                                    />
                                  ),
                                  propMap: {
                                    image: "gatsbyImage",
                                    "event.label": "name",
                                    to: createTeamMemberSlug,
                                  },
                                  children: [
                                    {
                                      component: <HoverContainer.Content />,
                                      children: [
                                        {
                                          component: (
                                            <div className={"name-container"} />
                                          ),
                                          children: [
                                            {
                                              component: <TeamMemberV2.Name />,
                                              propMap: { name: "name" },
                                            },
                                            {
                                              component: (
                                                <FishermanIcon
                                                  iconName={"arrow-right"}
                                                />
                                              ),
                                            },
                                          ],
                                        },
                                        {
                                          component: <TeamMemberV2.Role />,
                                          propMap: { role: "role" },
                                        },
                                        {
                                          component: (
                                            <TeamMemberV2.Social
                                              className={"staff-social"}
                                              linked={false}
                                            />
                                          ),
                                          propMap: { social: "socialMedia" },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ]}
                    />
                  }
                  groupHeaderComponent={<Header as={"h2"} />}
                  groupContainerComponent={
                    <Card.Group
                      itemsPerRow={4}
                      className={"team-members content-at-bottom group-items"}
                      stackable={true}
                    />
                  }
                  groupDescriptionKey={"description"}
                />
              </FilteredList>
              <div className={"reviews-section"}>
                <Header
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "reviews_section_header",
                    defaultValue: "",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "reviews_section_description",
                  })}
                />
                <BasicCarousel
                  className={"bordered-carousel reviews-carousel"}
                  size={1}
                  displayIndicators={false}
                  autoScroll={false}
                  displayPlayPauseButton={false}
                  animation={{ name: "blur", duration: 160 }}
                  items={multipleComponentIterator({
                    iterator: fishermanBusiness.reviews,
                    components: [
                      {
                        component: <ReviewV2 />,
                        propMap: { key: "_id" },
                        children: [
                          {
                            component: (
                              <ReviewV2.Text
                                charLimit={360}
                                seeMoreIcon={"plus"}
                                seeLessIcon={"minus"}
                              />
                            ),
                            propMap: { text: "text" },
                          },
                          {
                            component: <ReviewV2.Line />,
                            children: [
                              {
                                component: <ReviewV2.Rating />,
                                propMap: { rating: "rating" },
                              },
                              {
                                component: <ReviewV2.RatingLabel />,
                                propMap: { rating: "rating" },
                              },
                            ],
                          },
                          {
                            component: <ReviewV2.Line />,
                            children: [
                              {
                                component: <ReviewV2.Author />,
                                propMap: { author: "author" },
                              },
                              {
                                component: <ReviewV2.Source />,
                                propMap: { source: "source", link: "link" },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  })}
                />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Team" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        teams {
          _id
          name
          description
        }
        locations {
          _id
          name
        }
        show
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        name
      }
    }
    allFishermanBusinessTeamGroup(sort: { order: ASC, fields: order }) {
      nodes {
        name
      }
    }
  }
`;
